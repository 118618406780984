import React, { useState, useCallback } from 'react';
import Cropper from 'react-easy-crop';
import  getCroppedImg  from './cropImageHelper'; // A helper function to crop the image
import {Slider, Button} from '@material-ui/core'


const ImageCropAndAdjust = ({ imageSrc, onCropComplete,onRemoveImage, fileName, id, children }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImg, setCroppedImg] = useState(null)


  const onCropChange = useCallback((crop) => {
    setCrop(crop);
  }, []);

  const onZoomChange = useCallback((zoom) => {
    setZoom(zoom);
  }, []);

  const onRotationChange = useCallback((event, newValue) => {
    setRotation(newValue);
  }, []);

  const onCropCompleteHandler = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const onCropImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(imageSrc, fileName, croppedAreaPixels, rotation);
      setCroppedImg(croppedImage)
      onCropComplete(croppedImage);
    } catch (e) {
      console.error(e);
    }
  }, [imageSrc, croppedAreaPixels, rotation, onCropComplete]);

  return (
    <div>
      <div style={{ position: 'relative', width: '100%', height: 400 }}>
        <Cropper
          image={imageSrc}
          crop={crop}
          zoom={zoom}
          aspect={4 / 3}
          rotation={rotation}
          onCropChange={onCropChange}
          onZoomChange={onZoomChange}
          onCropComplete={onCropCompleteHandler}
          crossOrigin="anonymous"
        />
      </div>
      <div>
        <label style={{marginTop:'10px', fontSize:'1.1rem'}}>Zoom</label>
        <Slider
          value={zoom}
          min={1}
          max={3}
          step={0.1}
          onChange={(e, zoom) => onZoomChange(zoom)}
          style={{color:'green'}}
        />
      </div>

      <Button variant="contained" style={{background:'green', color:'#ffff', fontWeight:'800'}} onClick={(e)=>{
         e.preventDefault();
         e.stopPropagation()
         onCropImage()
      }}>
        Crop Image
      </Button>      
      {children}
    </div>
  );
};

export default ImageCropAndAdjust;